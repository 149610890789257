import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectAvailableMicrophones, selectSelectedMicrophone } from '../../selectors/audioV2';
import { selectMicrophone } from '../../store/slices/audioV2';
import { useAppDispatch, useAppSelector } from '../../store/store';
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 216,
    },
    sectionTitle: {
        padding: '4px 12px',
        fontSize: 10,
        fontWeight: 600,
        color: '#778290',
        textTransform: 'uppercase',
    },
    device: {
        display: 'flex',
        gap: 8,
        padding: '8px 12px',
        alignItems: 'center',
        cursor: 'pointer',
    },
    deviceDisabled: {
        cursor: 'pointer',
        color: theme.palette.ava.grey5,
    },
    deviceSelected: {
        background: '#F4F9FF',
        color: '#0679B4',
    },
    deviceStatus: {
        display: 'flex',
        alignItems: 'center',
        width: 12,
    },
    deviceSelectedIcon: {
        width: 12,
    },
}));
const ConvoV2AudioDeviceSelectorItem = React.memo(({ label, selected, onClick }) => {
    const classes = useStyles();
    return (React.createElement(Box, { className: classNames(classes.device, {
            [classes.deviceSelected]: selected,
            [classes.deviceDisabled]: !onClick,
        }), onClick: onClick },
        React.createElement(Box, { className: classes.deviceStatus }, selected && React.createElement(CheckIcon, { className: classes.deviceSelectedIcon })),
        label));
});
export const ConvoV2AudioDeviceSelector = React.memo(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const availableMicrophones = useAppSelector(selectAvailableMicrophones);
    const selectedMicrophone = useAppSelector(selectSelectedMicrophone);
    const dispatch = useAppDispatch();
    return (React.createElement(Box, { className: classes.root },
        React.createElement(Box, { className: classes.sectionTitle }, t('macInternalAudio.microphone')),
        availableMicrophones.map((mic) => (React.createElement(ConvoV2AudioDeviceSelectorItem, { key: mic.id, label: mic.isInternal ? t('macInternalAudio.avaMic') : mic.label, selected: mic.id === (selectedMicrophone === null || selectedMicrophone === void 0 ? void 0 : selectedMicrophone.id), onClick: () => dispatch(selectMicrophone(mic)) }))),
        availableMicrophones.length === 0 && (React.createElement(ConvoV2AudioDeviceSelectorItem, { label: t('micSettings.noMic'), selected: false }))));
});
