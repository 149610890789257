var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import SettingsVoiceOutlinedIcon from '@mui/icons-material/SettingsVoiceOutlined';
import { ListItemIcon } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AvaMic from '../../components/Icons/AvaMic';
import { selectAvailableMicrophones, selectSelectedMicrophone } from '../../selectors/audioV2';
import { selectMicrophone } from '../../store/slices/audioV2';
import { useAppDispatch, useAppSelector } from '../../store/store';
const MicSelector = () => {
    const { t } = useTranslation();
    const availableMicrophones = useAppSelector(selectAvailableMicrophones);
    const selectedMicrophone = useAppSelector(selectSelectedMicrophone);
    const dispatch = useAppDispatch();
    const handleChange = (event) => __awaiter(void 0, void 0, void 0, function* () {
        if (!event.target.value)
            return;
        dispatch(selectMicrophone(availableMicrophones.find((mic) => mic.id === event.target.value)));
    });
    if (!selectedMicrophone || !selectedMicrophone.label) {
        return React.createElement("div", null);
    }
    return (React.createElement("div", null,
        React.createElement(ListItem, { key: "mic_selected", role: undefined, dense: true },
            React.createElement(ListItemIcon, null,
                React.createElement(SettingsVoiceOutlinedIcon, null)),
            React.createElement(Select, { variant: "standard", value: selectedMicrophone.id, onChange: handleChange, style: { overflow: 'hidden', width: '100%' }, disabled: selectedMicrophone && availableMicrophones.length < 2 },
                availableMicrophones.map((x) => (React.createElement(MenuItem, { key: x.id, value: x.id },
                    React.createElement("span", { style: { display: 'flex', alignItems: 'center' } },
                        x.isInternal && React.createElement(AvaMic, { style: { marginRight: 10 } }),
                        React.createElement("span", null, x.isInternal ? t('macInternalAudio.avaMic') : x.label))))),
                availableMicrophones.length === 0 && (React.createElement(MenuItem, { value: undefined },
                    React.createElement("span", { style: { display: 'flex', alignItems: 'center' } },
                        React.createElement("span", null, t('micSettings.noMic')))))))));
};
export default MicSelector;
