import { selectStatusConversationMode } from '../selectors/legacy-conversation';
import { sendConversationModeUpdateMessage } from '../utils/ws-v1';
import { useOptimisticState } from './useOptimisticState';
import { useV1Socket } from './useV1Socket';
export function useConversationModeSwitcher() {
    const [ws] = useV1Socket();
    const [conversationMode, switchConversationMode] = useOptimisticState(selectStatusConversationMode, (value) => {
        if (ws)
            sendConversationModeUpdateMessage(ws, value);
    }, [ws, sendConversationModeUpdateMessage]);
    return { conversationMode, switchConversationMode };
}
