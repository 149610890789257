import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OpenOrDownloadAvaAppComponent from '../../components/OpenOrDownloadAvaAppComponent';
import { selectElectronCaptionMode, selectFullScreen } from '../../selectors/ui';
import { setCCMode } from '../../store/slices/ccMode';
import { useAppSelector } from '../../store/store';
const CCButton = ({ isDesktop }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showOpenOrDownloadAvaApp, setShowOpenOrDownloadAvaApp] = useState(false);
    const openAvaCallback = useCallback(() => {
        setShowOpenOrDownloadAvaApp(false);
        setTimeout(() => {
            setShowOpenOrDownloadAvaApp(true);
        }, 0);
    }, []);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const fullScreen = useAppSelector(selectFullScreen);
    const dispatch = useDispatch();
    if (isDesktop) {
        return (React.createElement(React.Fragment, null, !electronCaptionMode && !fullScreen && (React.createElement(Fab, { size: "small", style: {
                width: 56,
                height: 56,
                backgroundColor: theme.palette.ava.navy,
                color: theme.palette.ava.white,
            }, onClick: () => {
                dispatch(setCCMode('conversation'));
                navigate('/cc/conversation');
            } },
            React.createElement(ClosedCaptionIcon, { style: { fontSize: 36 } })))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Fab, { size: "small", style: {
                width: 56,
                height: 56,
                backgroundColor: theme.palette.ava.navy,
                color: theme.palette.ava.white,
            }, "data-qa": "ccDesktopAppButton", onClick: openAvaCallback },
            React.createElement(ClosedCaptionIcon, { style: { fontSize: 36 } })),
        showOpenOrDownloadAvaApp && React.createElement(OpenOrDownloadAvaAppComponent, null)));
};
export default CCButton;
