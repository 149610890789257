import { createSelector } from '@reduxjs/toolkit';
import { sortVoiceByGender } from '../utils/textToSpeech';
const selectTextToSpeech = (state) => state.textToSpeech;
export const selectTtsError = createSelector([selectTextToSpeech], (textToSpeech) => textToSpeech.ttsError);
export const selectIsUsingTts = createSelector([selectTextToSpeech], (textToSpeech) => textToSpeech.isUsingTts);
export const selectTextToSpeechManager = createSelector([selectTextToSpeech], (textToSpeech) => textToSpeech.textToSpeechManager);
export const selectUserTyping = createSelector([selectTextToSpeech], (texToSpeech) => texToSpeech.userTyping);
export const selectDisplayedItemsCount = createSelector([selectTextToSpeech], (textToSpeech) => textToSpeech.displayedItemsCount);
export const selectCurrentSelectedVoice = createSelector([selectTextToSpeech], (textToSpeech) => textToSpeech.currentSelectedVoice);
export const selectTtsGender = createSelector([selectTextToSpeech], (textToSpeech) => textToSpeech.ttsGender);
export const selectV1TtsVoices = createSelector([selectTextToSpeech], (textToSpeech) => textToSpeech.v1Voices);
export const selectV1TtsBestVoice = createSelector([selectTextToSpeech], (textToSpeech) => textToSpeech.v1TtsBestVoice);
export const selectTtsVoices = createSelector([selectTextToSpeech, selectTtsGender], (textToSpeech, gender) => {
    if (!gender)
        return textToSpeech.ttsVoices;
    else {
        const sortedVoices = sortVoiceByGender(textToSpeech.ttsVoices, gender);
        return sortedVoices;
    }
});
export const selectVoicesToShow = createSelector([selectTextToSpeech, selectTtsVoices, selectDisplayedItemsCount, selectCurrentSelectedVoice], (texToSpeech, ttsVoices, displayedItemsCount, currentSelectedVoice) => {
    const voicesToShow = Array.from(ttsVoices).filter(([_, voice]) => {
        // we basically want to show all voices besides the selected voice in the list
        // additionally we want to only show voices of the same gender as the selected voice
        // unless all voices are already shown ie we clicked showMoreButton
        const isNotSelectedVoice = voice.voiceProviderId !== (currentSelectedVoice === null || currentSelectedVoice === void 0 ? void 0 : currentSelectedVoice.voiceProviderId);
        const shouldMatchGender = displayedItemsCount !== ttsVoices.size && currentSelectedVoice;
        const isGenderMatch = shouldMatchGender ? voice.gender === (currentSelectedVoice === null || currentSelectedVoice === void 0 ? void 0 : currentSelectedVoice.gender) : true;
        return isNotSelectedVoice && isGenderMatch;
    });
    return voicesToShow.slice(0, displayedItemsCount);
});
export const selectIsTtsLoading = createSelector([selectTextToSpeech], (textToSpeech) => textToSpeech.isTtsLoading);
