import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { Collapse, List, ListItemIcon } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggled';
import { useSpeechLangSwitcher } from '../../hooks/useSpeechLangSwitcher';
import { canShowFlagEmoji } from '../../utils/platform';
import { EmptyIcon, styles } from './SidebarHelpers';
const useStyles = makeStyles(styles);
const LangSelector = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { activeSpeechLang, allSpeechLangs, switchSpeechLang } = useSpeechLangSwitcher();
    const currentFlagCode = activeSpeechLang === null || activeSpeechLang === void 0 ? void 0 : activeSpeechLang.flagCode;
    return (React.createElement(Toggle, null, ({ on, getTogglerProps, setOff }) => (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'column',
        } },
        React.createElement("div", null,
            React.createElement(ListItemButton, Object.assign({ key: "lang_selected" }, getTogglerProps()),
                React.createElement(ListItemIcon, null,
                    React.createElement(TextsmsOutlinedIcon, null)),
                React.createElement(ListItemText, { style: { paddingRight: 0 } },
                    t('micSettings.chooseLang'),
                    React.createElement("br", null),
                    currentFlagCode && (React.createElement(ReactCountryFlag, { style: { marginRight: 5 }, svg: !canShowFlagEmoji(), countryCode: currentFlagCode })),
                    React.createElement("b", { style: { fontWeight: 600 } }, activeSpeechLang === null || activeSpeechLang === void 0 ? void 0 : activeSpeechLang.label)),
                React.createElement(ListItemIcon, { style: { marginRight: 0, minWidth: 0 } }, on ? React.createElement(ExpandLessOutlinedIcon, null) : React.createElement(ExpandMoreOutlinedIcon, null)))),
        React.createElement(Collapse, { in: on },
            React.createElement(List, null, allSpeechLangs.map((lang) => (React.createElement(ListItemButton, { key: lang.value, style: {
                    display: activeSpeechLang === lang ? 'none' : undefined,
                } },
                React.createElement(EmptyIcon, null),
                React.createElement(ListItemText, { classes: { root: classes.listItemText }, onClick: () => {
                        switchSpeechLang(lang.value);
                        setOff();
                    } },
                    React.createElement(ReactCountryFlag, { style: { marginRight: 5 }, svg: !canShowFlagEmoji(), countryCode: lang.flagCode }),
                    lang.label))))))))));
};
export default LangSelector;
