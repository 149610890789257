// From https://stackoverflow.com/questions/1125292/how-to-move-cursor-to-end-of-contenteditable-entity/3866442#3866442
export function setEndOfContenteditable(contentEditableElement) {
    const range = document.createRange(); // Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement); // Select the entire contents of the element with the range
    range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
    const selection = window.getSelection(); // get the selection object (allows you to change selection)
    selection.removeAllRanges(); // remove any selections already made
    selection.addRange(range); // make the range you have just created the visible selection
}
export function setStartOfContenteditable(contentEditableElement) {
    const range = document.createRange();
    range.selectNodeContents(contentEditableElement);
    range.collapse(true);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
}
export function selectElementContents(el) {
    const range = document.createRange();
    range.selectNodeContents(el);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
}
export function getCaretPosition() {
    if (window.getSelection && window.getSelection().getRangeAt) {
        const range = window.getSelection().getRangeAt(0);
        const selectedObj = window.getSelection();
        let rangeCount = 0;
        const { anchorNode: { parentNode: { childNodes }, }, } = selectedObj;
        for (let i = 0; i < childNodes.length; i += 1) {
            if (childNodes[i] === selectedObj.anchorNode) {
                break;
            }
            if (childNodes[i].outerHTML)
                rangeCount += childNodes[i].outerHTML.length;
            else if (childNodes[i].nodeType === 3) {
                rangeCount += childNodes[i].textContent.length;
            }
        }
        return range.startOffset + rangeCount;
    }
    return -1;
}
export function simulateClick(url = 'ava.me://open') {
    const link = document.createElement('a');
    link.href = url;
    if (document.body) {
        document.body.appendChild(link);
    }
    link.click();
    if (document.body) {
        document.body.removeChild(link);
    }
}
