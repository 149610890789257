import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import Collapse from '@mui/material/Collapse/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { makeStyles } from '@mui/styles';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggled';
import { useTranslationLangSwitcher } from '../../hooks/useTranslationLangSwitcher';
import { selectCanSwitchTranslation } from '../../selectors/avaTranslate';
import { useAppSelector } from '../../store/store';
import { getLanguageNativeName } from '../../utils/AvaTranslate';
import { canShowFlagEmoji } from '../../utils/platform';
import { EmptyIcon, styles } from './SidebarHelpers';
const useStyles = makeStyles(styles);
const TranslationSelector = React.memo(function TranslationSelector() {
    const classes = useStyles();
    const { t } = useTranslation();
    const canSwitchTranslation = useAppSelector(selectCanSwitchTranslation);
    const { allTranslationLangs, activeTranslationLang, switchTranslationLang, stopTranslating } = useTranslationLangSwitcher();
    const currentFlagCode = activeTranslationLang === null || activeTranslationLang === void 0 ? void 0 : activeTranslationLang.flagCode;
    if (!canSwitchTranslation) {
        return null;
    }
    return (React.createElement(Toggle, null, ({ on, getTogglerProps, toggle }) => (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'column',
        } },
        React.createElement("div", null,
            React.createElement(ListItem, Object.assign({ button: true }, getTogglerProps()),
                React.createElement(ListItemIcon, null,
                    React.createElement(LanguageOutlinedIcon, null)),
                React.createElement(ListItemText, { classes: { root: classes.listItemText } },
                    t('sidebar.switchLang'),
                    React.createElement("br", null),
                    React.createElement("b", { style: { fontWeight: 600 } },
                        currentFlagCode && (React.createElement(ReactCountryFlag, { style: { marginRight: 5 }, svg: !canShowFlagEmoji(), countryCode: currentFlagCode })),
                        !activeTranslationLang
                            ? `\uD83C\uDF0D \u00A0${t('sidebar.chooseLanguage')}`
                            : getLanguageNativeName(activeTranslationLang.languageCode))),
                React.createElement(ListItemIcon, { classes: { root: classes.expandIcon } }, on ? React.createElement(ExpandLessOutlinedIcon, null) : React.createElement(ExpandMoreOutlinedIcon, null))),
            React.createElement(Collapse, { in: on },
                React.createElement(List, null,
                    activeTranslationLang && (React.createElement(ListItem, { key: "~", button: true },
                        React.createElement(EmptyIcon, null),
                        React.createElement(ListItemText, { classes: { root: classes.listItemText }, onClick: () => {
                                toggle();
                                stopTranslating();
                            } },
                            React.createElement("span", { role: "img", "aria-label": "cancel" }, "\u274C"),
                            ' ',
                            t('sidebar.stopTranslation')))),
                    allTranslationLangs.map(({ languageCode, flagCode, label }) => (React.createElement(ListItem, { key: languageCode, button: true },
                        React.createElement(EmptyIcon, null),
                        React.createElement(ListItemText, { classes: { root: classes.listItemText }, onClick: () => {
                                toggle();
                                switchTranslationLang(languageCode);
                            } },
                            React.createElement(ReactCountryFlag, { style: { marginRight: 5 }, svg: !canShowFlagEmoji(), countryCode: flagCode }),
                            label)))))))))));
});
export default TranslationSelector;
