import { useMemo } from 'react';
import translationLangs from '../../assets/translation_langs.json';
import { switchLang } from '../actions';
import { selectAudioV2Status, selectSelectedMicrophone } from '../selectors/audioV2';
import { selectCanRecord } from '../selectors/combined';
import { selectCurseFilter } from '../selectors/conversation';
import { selectLang, selectSpeechLang } from '../selectors/legacy-conversation';
import { maybeRestartRecording, prepareAudioParams, RecordingStatus, selectMicrophone } from '../store/slices/audioV2';
import { useAppDispatch, useAppSelector } from '../store/store';
import { getLanguageNativeName } from '../utils/AvaTranslate';
import { sendAudioParams } from '../utils/ws-v1';
import { useOptimisticState } from './useOptimisticState';
import { useV1Socket } from './useV1Socket';
export const NO_TRANSLATION = '~';
export function useTranslationLangSwitcher() {
    const [ws] = useV1Socket();
    const speechLang = useAppSelector(selectSpeechLang);
    const curseFilter = useAppSelector(selectCurseFilter);
    const recording = useAppSelector(selectAudioV2Status) === RecordingStatus.RECORDING;
    const canRecord = useAppSelector(selectCanRecord);
    const selectedMicrophone = useAppSelector(selectSelectedMicrophone);
    const dispatch = useAppDispatch();
    const [activeTranslationLangCode, switchTranslationLang] = useOptimisticState(selectLang, (languageCode) => {
        dispatch(switchLang(languageCode));
        dispatch(maybeRestartRecording());
        if (ws && (!recording || !canRecord)) {
            const audioParams = prepareAudioParams(curseFilter, languageCode, speechLang, selectedMicrophone);
            sendAudioParams(ws, audioParams);
        }
    }, [
        ws,
        recording,
        canRecord,
        curseFilter,
        speechLang,
        dispatch,
        selectMicrophone,
        switchLang,
        maybeRestartRecording,
        sendAudioParams,
    ]);
    const allTranslationLangs = useMemo(() => translationLangs.map((translationLang) => (Object.assign(Object.assign({}, translationLang), { label: getLanguageNativeName(translationLang.languageCode) }))), [translationLangs]);
    const activeTranslationLang = useMemo(() => allTranslationLangs.find((lang) => lang.languageCode === activeTranslationLangCode), [allTranslationLangs, activeTranslationLangCode]);
    return {
        activeTranslationLang,
        allTranslationLangs,
        switchTranslationLang,
        stopTranslating: () => switchTranslationLang(NO_TRANSLATION),
    };
}
