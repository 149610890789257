import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useConversationModeSwitcher } from '../../hooks/useConversationModeSwitcher';
export function ConvoV2ConversationModeSelector() {
    const { t } = useTranslation();
    const { conversationMode, switchConversationMode } = useConversationModeSwitcher();
    return (React.createElement(Select, { value: conversationMode, onChange: (event) => switchConversationMode(event.target.value), 
        // Without this, the whole settings card is hidden as soon as you open the select
        MenuProps: { disablePortal: true } },
        React.createElement(MenuItem, { value: "public" }, t('conversation.conversationMode.interactiveMode')),
        React.createElement(MenuItem, { value: "public-muted" }, t('conversation.conversationMode.readOnlyMode'))));
}
