import { useCallback, useRef, useState } from 'react';
import { useAppSelector } from '../store/store';
export const useOptimisticState = (selectBackendValue, updateBackendValue, dependencies) => {
    const backendValue = useAppSelector(selectBackendValue);
    const [optimisticValue, setOptimisticValue] = useState();
    const timeout = useRef(undefined);
    const update = useCallback((value) => {
        updateBackendValue(value);
        setOptimisticValue(value);
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = undefined;
        }
        timeout.current = setTimeout(() => {
            setOptimisticValue(undefined);
        }, 5000);
    }, dependencies);
    return [optimisticValue !== null && optimisticValue !== void 0 ? optimisticValue : backendValue, update];
};
