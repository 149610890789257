import { useEffect, useMemo } from 'react';
import availableLangs from '../../assets/langs.json';
import { setLangSelected } from '../actions';
import { selectSpeechLang } from '../selectors/legacy-conversation';
import { selectRecallAIStatus } from '../selectors/recallAI';
import { maybeRestartRecording } from '../store/slices/audioV2';
import { useAppDispatch, useAppSelector } from '../store/store';
import { useOptimisticState } from './useOptimisticState';
import useRecallAIManager from './useRecallAIManager';
export function useSpeechLangSwitcher() {
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const recallAIManager = useRecallAIManager();
    const dispatch = useAppDispatch();
    const [speechLangValue, switchSpeechLang] = useOptimisticState(selectSpeechLang, (langValue) => {
        localStorage.setItem('speechLang', langValue);
        dispatch(setLangSelected(langValue));
        dispatch(maybeRestartRecording());
    }, [dispatch, setLangSelected, maybeRestartRecording]);
    useEffect(() => {
        if (recallAIStatus === 'CAPTIONING') {
            recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.handleChangeLang(speechLangValue);
        }
    }, [speechLangValue]);
    const allSpeechLangs = useMemo(() => Object.entries(availableLangs).map(([key, availableLang]) => ({
        value: availableLang.languageCode,
        label: key,
        flagCode: availableLang.flagCode,
    })), [availableLangs]);
    const activeSpeechLang = useMemo(() => allSpeechLangs.find((l) => l.value === speechLangValue), [allSpeechLangs, speechLangValue]);
    return { activeSpeechLang, allSpeechLangs, switchSpeechLang };
}
