import { useTheme } from '@mui/material';
import React from 'react';
export const ConvoV2CaptionQualityIcon = React.memo(({ captionQuality }) => {
    const theme = useTheme();
    let centerColor;
    let rightTopColor;
    let bottomColor;
    let leftTopColor;
    if (captionQuality === 'premium') {
        rightTopColor = bottomColor = theme.palette.ava.grey8;
        centerColor = leftTopColor = theme.palette.ava.grey5;
    }
    else if (captionQuality === 'scribe') {
        rightTopColor = leftTopColor = bottomColor = centerColor = theme.palette.ava.scribe2;
    }
    else {
        if (captionQuality !== 'free') {
            console.warn(`Invalid caption quality: ${captionQuality}`);
        }
        centerColor = bottomColor = leftTopColor = theme.palette.ava.grey5;
        rightTopColor = '#50ACED';
    }
    return (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 19 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M6.94323 11.4924C7.77881 12.9055 9.60124 13.3675 11.0137 12.5241C12.4262 11.6808 12.8939 9.85165 12.0584 8.43853C11.2228 7.02541 9.40035 6.56348 7.98785 7.40679C6.57534 8.25009 6.10765 10.0793 6.94323 11.4924Z", fill: centerColor }),
        React.createElement("path", { d: "M10.6369 4.11499C11.4645 4.27413 12.248 4.60815 12.9356 5.09485C13.6233 5.58155 14.1991 6.20979 14.6251 6.93784C15.051 7.6659 15.3172 8.47709 15.4059 9.31754C15.4947 10.158 15.4041 11.0084 15.14 11.8124L18.2722 13.5982C18.8364 12.2345 19.078 10.7594 18.978 9.28875C18.878 7.81815 18.4392 6.39215 17.696 5.1228C16.9528 3.85346 15.9255 2.77537 14.6949 1.97325C13.4642 1.17112 12.0637 0.666832 10.6033 0.5L10.6369 4.11499Z", fill: rightTopColor }),
        React.createElement("path", { d: "M13.9611 13.8803C13.4083 14.513 12.7289 15.022 11.9674 15.3739C11.2059 15.7258 10.3794 15.9126 9.54213 15.9222C8.70487 15.9318 7.87573 15.7639 7.10902 15.4295C6.34232 15.0951 5.65535 14.6017 5.09315 13.9817L1.99338 15.8324C2.88952 16.9892 4.04066 17.9219 5.35716 18.558C6.67366 19.194 8.11997 19.5162 9.58348 19.4994C11.047 19.4826 12.4882 19.1273 13.7948 18.4612C15.1015 17.7951 16.2383 16.8361 17.1168 15.659L13.9611 13.8803Z", fill: bottomColor }),
        React.createElement("path", { d: "M3.88279 11.9348C3.43516 10.6381 3.44866 9.22446 3.92102 7.93307C4.39337 6.64168 5.29556 5.55188 6.4749 4.8481C7.02864 4.51939 7.6309 4.28138 8.25886 4.14307L8.22735 0.519909C6.77282 0.723913 5.38384 1.26264 4.16921 2.09389C2.95459 2.92513 1.9473 4.02633 1.22624 5.31122C0.505188 6.59612 0.0899514 8.02981 0.0130567 9.50001C-0.0638389 10.9702 0.199695 12.437 0.783017 13.7854L3.88279 11.9348Z", fill: leftTopColor })));
});
