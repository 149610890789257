import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Transcript from '../../components/Icons/Transcript';
import { ConvoV2PrimaryButton } from './ConvoV2PrimaryButton';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    content: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        borderRadius: 20,
        border: `1px solid ${theme.palette.ava.blue4}`,
        padding: 40,
        flexDirection: 'column',
        alignItems: 'center',
        gap: 20,
    },
    description: {
        fontFamily: 'AdelleSans',
        fontSize: 20,
        fontWeight: 500,
    },
    actionButton: {
        position: 'absolute',
        left: '50%',
        bottom: 0,
        transform: 'translate(-50%, 50%)',
    },
}));
export const ConvoV2PastSessionsPlaceholder = React.memo(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (React.createElement(Box, { className: classes.root },
        React.createElement(Box, { className: classes.content },
            React.createElement(Transcript, { width: 130, height: 130 }),
            React.createElement(Box, { className: classes.description }, t('convoV2.transcripts.willAppearHere')),
            React.createElement(ConvoV2PrimaryButton, { className: classes.actionButton }, t('convoV2.transcripts.enableSavePastSessions')))));
});
