import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectCaptionQuality } from '../../selectors/conversation';
import { useAppSelector } from '../../store/store';
import { ConvoV2CaptionQualityIcon } from './ConvoV2CaptionQualityIcon';
const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 16,
        border: `1px solid ${theme.palette.ava.grey7}`,
        borderRadius: 30,
        padding: '14px 14px',
        maxWidth: 49,
        height: 49,
        transition: '300ms max-width ease, 300ms gap ease',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&:hover': {
            transitionDelay: '3s',
            maxWidth: 200,
            paddingRight: 15,
            gap: 10,
        },
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
    },
}));
export const ConvoV2CaptionQualityButton = React.memo(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const captionQuality = useAppSelector(selectCaptionQuality);
    return (React.createElement(Box, { className: classes.root },
        React.createElement(Box, { className: classes.icon },
            React.createElement(ConvoV2CaptionQualityIcon, { captionQuality: captionQuality })),
        captionQuality === 'free' && t('accuracy.freeAccuracyShort'),
        captionQuality === 'premium' && t('accuracy.premiumAccuracyShort'),
        captionQuality === 'scribe' && t('accuracy.scribeAccuracyShort')));
});
