import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    reconnectBanner: {
        position: 'fixed',
    },
    volumeController: {
        opacity: (props) => (props.conversationContainerHovered ? 1 : 0),
        transition: 'opacity 200ms',
    },
}));
export default useStyles;
